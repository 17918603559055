import React, { Component } from "react";
import "moment/locale/id.js";
import { Link } from "react-router-dom";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "react-loader-spinner";
import LoadingSpinner from "../components/components/LoadingSpinner";
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBCard,
  MDBCardBody,
  MDBBtn,
} from "mdbreact";

const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    promiseInProgress && (
      <div
        style={{
          width: "100%",
          height: "100",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#fff",
        }}
      >
        <Loader type="ThreeDots" color="#3c8dbc" height="100" width="100" />
      </div>
    )
  );
};

class Upload_dokumendetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      student_id: "",
      data_siswa: "",
      document_id: "",
      note: "",
      encrypt_64: "",
      original_name: "123",
      is_show: false,
      chkbox1: false,
      chkbox2: false,
      next: true,
      data_upload: [],
      data_image: [],
      note_document_upload: "",
      info_swal: "",
      file: null,
      document: [],
      prm_document: [],
    };
  }

  componentDidMount() {
    let paramDoc = JSON.parse(localStorage.getItem("DATA_PARAMETER")).prm_document_upload;
    let docUpload = JSON.parse(localStorage.getItem("STUDENT_DETAIL")).document_upload;

    paramDoc.map((item, idx) => {
      item.photo = "";
      item.note = "";
      item.selectedFile = null;
    })

    if (docUpload.length > 0) {
      docUpload.map((item, idx) => {
        const index = paramDoc.findIndex((i) => i.id === item.document_id);
        if (index > -1) {
          paramDoc[index].photo = item.link;
          paramDoc[index].note = item.note;
        }
      })
    }

    this.setState({
      student_id: localStorage.getItem("DATA_JALUR").split("-")[0],
      encrypt_64: "",
      original_name: "",
      prm_document: paramDoc,
      school_name: JSON.parse(localStorage.getItem("DATA_PARAMETER")).prm_unit.name,
      note_document_upload: JSON.parse(localStorage.getItem("STUDENT_DETAIL")).note_document_upload,
    });
  }

  onChangeHandler = (param) => (e) => {
    const fieldname = e.target.getAttribute("fieldname");
    const idx = e.target.getAttribute("index");
    let newList = [];
    newList = this.state[fieldname];

    var file = e.target.files[0];
    if (file) {
      if (file.size > 5250000) {
        global.swal("Error", "File tidak boleh lebih dari 5 MB", "info");
        newList[idx].selectedFile = "";
        newList[idx].original_name = "";
      } else if (param === 1) {
        if (file.type === "image/png" || file.type === "image/jpeg") {
          newList[idx].selectedFile = file;
          newList[idx].original_name = file.name;
        } else {
          global.swal(
            "Error",
            "Harap pilih gambar berupa (*.jpg atau *.png) untuk pas foto",
            "info"
          );
          newList[idx].selectedFile = "";
          newList[idx].original_name = "";
          return
        }
      } else if (param === 21 || file.type === "image/png" || file.type === "image/jpeg" || file.type === "application/pdf") {
        newList[idx].selectedFile = file;
        newList[idx].original_name = file.name;
      } else {
        global.swal(
          "Error",
          "Harap pilih gambar berupa (*.jpg atau *.png) atau pdf",
          "info"
        );
        // this.setState({ selectedFile: "", original_name: "" });
        newList[idx].selectedFile = "";
        newList[idx].original_name = "";
        return
      }
      const findIdx = this.state.prm_document.findIndex(
        (i) => i.id === newList[idx].id
      );
      this.state.prm_document[findIdx].selectedFile = file;
      this.state.prm_document[findIdx].original_name = file.name;
      this.state.prm_document[findIdx].document_id = this.state.prm_document[findIdx].id;

      const item = this.state.document.find(data => data.id === newList[findIdx].id)
      if (item) {
        item.selectedFile = file;
        item.original_name = file.name;
      } else {
        this.state.document.push(newList[findIdx]);
      }
      this.setState({ [fieldname]: newList });
    } else {
      if (this.state.document.length > 1) {
        const idxDoc = this.state.document.findIndex(
          (i) => i.id === param
        )
        this.state.document.filter((data, idx) => {
          if (idxDoc === idx) {
            this.state.document.splice(idxDoc, 1);
          }
        });
        this.setState({ document: this.state.document });
      }
    }
  };

  getTahunAjaran = () => {
    let paramUnit = JSON.parse(localStorage.getItem("DATA_PARAMETER")).prm_unit;
    let paramYear = JSON.parse(localStorage.getItem("DATA_PARAMETER")).prm_study_year;
    const dataYear = paramYear.find(data => data.id === paramUnit.registration_year);
    return dataYear.name;
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  onDropdownSelected(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit = async (event) => {
    this.setState({ isLoading: true })
    event.preventDefault();
    await this.uploadfile();
  };

  handleChangeChk = (e) => {
    this.setState({
      [e.target.name]: e.target.checked,
    });
  };

  handleBack = () => {
    this.props.history.push("/uploaddokumen");
  };

  async uploadfile() {
    const { document, subdomain, prm_document } = this.state;
    const newList = this.state.prm_document;
    let school_name = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    ).prm_unit;
    if (this.state.chkbox1 && this.state.chkbox2) {
      Promise.all(
        //prm_document diambil dri parameter prm_document_detail
        prm_document.map(async (item, idx) => {
          if (parseInt(item.is_required) === 1) {
            if (item.photo === "" && item.selectedFile === null) {
              this.setState({ isLoading: false })
              global.swal(
                "Error",
                "Harap mengupload gambar yang wajib (* tanda bintang) ",
                "info"
              );
              return false;
            }
          }
          if (item.selectedFile && item.selectedFile !== null) {
            const data = new FormData();
            data.append("download_document", item.selectedFile);
            data.append("name_file", item.original_name);

            await fetch(
              global.variable.LINK_API + "settings/param/uploadFile",
              {
                method: "post",
                headers: {
                  Accept: "application/json",
                  Authorization: "Bearer " + global.cookies.get("access_token"),
                },
                body: data,
              }
            )
              .then(this.handleErrors)
              .then((response) => response.json())
              .then((resData) => {
                this.setState({ isLoading: false })
                if (resData.message === "Success") {
                  newList[idx].link = resData.link;
                  newList[idx].note = '';
                  newList[idx].student_id = this.state.student_id;
                  this.forceUpdate();
                } else {
                  global.swal("Error", resData.error, "info");
                }
              });
          }

          return item;
        })
      ).then((res) => {
        // var required = [];
        // var link = [];
        // newList.map((check) => {
        //   if (check.is_required === 1) {
        //     required.push(1);
        //     if ('link' in check) link.push(1);
        //   }
        // })
        // var same = required.length === link.length && required.every(function (element, index) {
        //   return element === link[index];
        // });
        // if (!same) {
        //   this.setState({ isLoading: false })
        //   global.swal(
        //     "Error",
        //     "Harap mengupload gambar yang wajib (* tanda bintang)",
        //     "info"
        //   );
        //   return false;
        // }
        const data = new FormData();
        const note = [];
        let items;
        items = Object.create({});
        items.field = "note_document_upload";
        items.value = this.state.note_document_upload;
        note.push(items);
        data.append("registration_id", this.state.student_id);
        data.append("action", "next");
        data.append("data", JSON.stringify(note));
        data.append("document", JSON.stringify(res.filter((item) => item.original_name != null)));

        fetch(global.variable.LINK_API + "flow/update", {
          method: "post",
          headers: {
            Accept: "application/json",
            // "Content-Type": "application/json",
            Authorization: "Bearer " + global.cookies.get("access_token"),
          },
          body: data,
        })
          .then(this.handleErrors)
          .then((response) => response.json())
          .then((resData) => {
            if (resData.message === "Success") {
              //Jika tidak menggunakan VA dan data awal
              var text = "Terima kasih anda telah mendaftar ke " + school_name.name;

              //Jika tidak menggunakan VA dan data yang dikembalikan
              if (this.state.is_send_notif_payment_register) {
                text = "Silahkan menunggu proses validasi ulang dokumen anda dalam waktu maksimal 24 jam";
              }
              this.setState({ info_swal: text });
              this.runswal();
            }
          }).catch((err) => {
            this.setState({ isLoading: false })
            if (err) {
              if (err.code !== 403) {
                let message;
                if ('errors' in err) {
                  if (Array.isArray(err.errors)) {
                    message = err.errors[0];
                  } else {
                    message = err.errors;
                  }
                } else {
                  message = "Koneksi ke server gagal.";
                }
                global.swal("Error", message, "info");
              }
            } else {
              global.swal.stopLoading();
              global.swal.close();
            }
          });
        // }

      });
    } else {
      this.setState({ isLoading: false })
      global.swal(
        "Error",
        "Harap mencentang semua persyaratan",
        "info"
      );
    }
    // })
  }

  handleErrors = async (response) => {
    this.setState({ isLoading: false })
    if (!response.ok) {
      const errors = await response.clone().json();
      const errorMessage = { code: response.status, ...errors }
      throw errorMessage;
    }
    return response;
  }

  runswal() {
    var span = document.createElement("span");
    //span.innerHTML = "Terima kasih anda telah mendaftar ke SMP PL Domenico Savio Semarang. \n\nNomor Pendaftaran anda adalah " + resData.data.year + '-' + resData.data.registration_type_id  + '-' +  resData.data.registration_number +"\n\n<strong>Silahkan melakukan transfer biaya pendaftaran sebesar Rp.100.000 ke rekening Bank Mandiri no 136-00-0149000-9 atas nama SMP PANGUDI LUHUR DOMENICO SAVIO SMG dengan mencantumkan nomor pendaftaran anda pada kolom catatan.</strong> \n\nData anda akan kami proses dalam waktu maksimal 24 jam setelah anda mengunggah bukti transfer.";
    span.innerHTML = this.state.info_swal;

    global
      .swal({
        title:
          "Success" /*"Kode Pembayaran anda adalah " + resData.data.year + resData.data.registration_type_id + resData.data.registration_number + "\n Nomor Rekening : 136-00-0149000-9 \na/n SMP Pangudi Luhur Domenico Savio SMG"*/,
        icon: "success",

        content: span,
      })
      .then((value) => {
        this.props.history.push("/uploaddokumen");
      });
  }

  download = (param) => (e) => {
    e.preventDefault();
    // console.log(param)
    window.open(param, "_blank");
  };

  handlefile(e) {
    let base64String = e;
    let base64Image = base64String.split(";base64,").pop();

    // console.log( base64Image);

    const element = document.createElement("a");
    const file = new Blob(base64Image, { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "myFile.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  render() {
    console.log(this.state.isLoading)
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <MDBBreadcrumb color="cyan lighten-4">
                    <MDBBreadcrumbItem active>
                      <a href="/home">Home</a>
                    </MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Upload Dokumen</MDBBreadcrumbItem>
                  </MDBBreadcrumb>
                  {this.state.isLoading ? <LoadingSpinner /> : ""}
                  <form>
                    <MDBCard className="mb-5">
                      <MDBCardBody>
                        <label htmlFor="exampleForm2" style={{ fontWeight: "bold" }} >
                          Nama Siswa
                        </label>
                        <input
                          className="form-control"
                          type="Text"
                          name="jalur"
                          value={localStorage.getItem("DATA_JALUR")}
                          disabled
                        />
                        <label htmlFor="exampleForm2" style={{ fontWeight: "bold" }} >
                          Semua file berupa image (*.jpg dan *.png) atau pdf dan
                          tidak lebih dari 5 MB
                        </label>
                        <hr />
                        {this.state.prm_document.map((item, idx) => {
                          let download_document;
                          if (item.download_document) {
                            download_document = item.download_document;
                          }
                          return (
                            <div key={item.id}>
                              <label>
                                {item.name}{" "}
                                {
                                  parseInt(item.is_required) === 1 ? (<span className="text-danger">*</span>) : ""
                                }
                              </label>
                              <div style={{ marginLeft: 10 }}>
                                {download_document ? (
                                  <Link to="" target="_blank"
                                    onClick={this.download(download_document)}
                                  >
                                    Download Template
                                  </Link>
                                ) : null}
                              </div>
                              <div className="form-row">
                                <div className="form-group col-md-6">
                                  <input
                                    type="file"
                                    name="file"
                                    accept=".jpg, .jpeg, .png, .pdf"
                                    index={idx}
                                    fieldname="prm_document"
                                    className="form-control"
                                    onChange={this.onChangeHandler(item.id)}
                                  />
                                </div>
                                <div className="form-group col-md-6">
                                  <div style={{ marginLeft: 10 }}>
                                    {item.photo !== "" ? (
                                      <div>
                                        <a href={item.photo} target="_blank" rel="noopener noreferrer" >
                                          <strong>Lihat Dokumen</strong>
                                        </a>
                                        <hr style={{ margin: 10 }}></hr>
                                        <span style={{ color: "red" }}>
                                          <strong>
                                            Dokumen sudah diupload
                                          </strong>
                                        </span>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <hr />
                            </div>
                          );
                        })}

                        <LoadingIndicator />

                        <label
                          htmlFor="exampleForm2"
                          style={{
                            fontSize: 20,
                            textDecorationLine: "underline",
                          }}
                        >
                          Catatan Dokumen
                        </label>
                        <br></br>

                        <label htmlFor="exampleForm2">Catatan</label>
                        <textarea
                          className="form-control"
                          type="textarea"
                          rows="5"
                          name="note_document_upload"
                          maxLength="500"
                          // fieldname="document"
                          value={this.state.note_document_upload}
                          onChange={this.onChange}
                        />
                        <div className="rowHorizontal">
                          <input
                            className="form-check-input"
                            id="flexCheckBox1"
                            type="checkbox"
                            name="chkbox1"
                            value={this.state.chkbox1}
                            onChange={this.handleChangeChk}
                          />
                          <label
                            style={{ margin: 5 }}
                            className="form-check-label"
                            htmlFor="flexCheckBox1"
                          >
                            Saya mengisi semua Data diatas dalam keadaan Sehat,
                            Sadar dan Informasi yang Saya tuliskan dalam Data tersebut adalah Jujur dan Benar.
                          </label>
                        </div>
                        <div className="rowHorizontal">
                          <input
                            className="form-check-input"
                            id="flexCheckBox2"
                            type="checkbox"
                            name="chkbox2"
                            defaultChecked={this.state.chkbox2}
                            onChange={this.handleChangeChk}
                          />
                          <label
                            style={{ margin: 5 }}
                            className="form-check-label"
                            htmlFor="flexCheckBox2"
                          >
                            Apabila di kemudian hari terbukti ditemukan Kebohongan,
                            Pemalsuan atau Kesalahan yang disengaja pada Penulisan
                            Informasi maupun Dokumen Pelengkap,
                            maka Kami bersedia menanggung resiko berupa :
                          </label>
                        </div>
                        <div className="rowHorizontal">
                          <label style={{ margin: 5, marginLeft: 40 }}>
                            a.
                          </label>
                          <label style={{ margin: 5 }}>
                            Dibatalkan dari Pencalonannya sebagai
                            Calon Peserta Didik Baru,
                            apabila belum memasuki Tahun Ajaran {this.getTahunAjaran()}
                          </label>
                        </div>
                        <div className="rowHorizontal">
                          <label style={{ margin: 5, marginLeft: 40 }}>
                            b.
                          </label>
                          <label style={{ margin: 5 }}>
                            Dikeluarkan dari {this.state.school_name}, apabila
                            sudah menjalani Tahun Ajaran {this.getTahunAjaran()}
                          </label>
                        </div>
                        <div className="rowHorizontal">
                          <label style={{ margin: 5, marginLeft: 40 }}>
                            c.
                          </label>
                          <label style={{ margin: 5 }}>
                            Mendapatkan Penyesuaian kembali
                            terhadap besaran uang yang harus
                            ditanggung oleh Peserta Didik
                          </label>
                        </div>
                      </MDBCardBody>
                    </MDBCard>
                    {/* {this.state.is_show ? ( */}
                    <div className="row">
                      <div className="col-md-12">
                        <MDBBtn
                          color="primary"
                          disabled={this.state.isLoading}
                          onClick={this.handleSubmit}
                          block
                          style={{ margin: 5, height: 50 }}
                        >
                          {
                            this.state.isLoading ? (
                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            ) : 'kirim data untuk diverifikasi'
                          }
                        </MDBBtn>
                      </div>
                      {/* <MDBBtn onClick={this.handleSave( item.is_required ||(item.id === 21 &&this.state.both_parent_work===1))} block style={{margin:5, height:50}}>Submit</MDBBtn> */}
                    </div>
                    {/* ) : (
                        ""
                      )} */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Upload_dokumendetail;
